import store from '@/store'
import {getTime} from "@/utils/time";

export function getAuthRule() {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    if (!id || !token) {
        return false
    }
    if (store.state.authRule.length === 0) {
        store.dispatch('getRuleList')
    }
    return store.state.authRule;
}
export function authCheck(authRule,auth) {
    return Object.values(authRule).indexOf(auth) >= 0;
}

export function getLocalStorage() {
    return {
        token: localStorage.getItem('token') || '',
        uid: localStorage.getItem('uid') || 0
    }
}

export function getUserInfo() {
    if (store.state.userInfo.length === 0) {
        store.dispatch('getUserInfo')
    }
    return store.state.userInfo;
}

export function imageUrl(path) {
    if (!path) {
        return "";
    }
    return process.env.VUE_APP_OSS_URL + path
}
export function authCheckRouter(arrObj){
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    if (!id || !token) {
        return false
    }
    if (store.state.authRule.length === 0) {
        store.dispatch('getRuleList')
    }
    let newArrObj =  arrObj.filter((data)=>{
        return Object.values(store.state.authRule).indexOf(data.url) >= 0
    })
    return newArrObj;
}

export async function getStsToken() {
    if (store.state.getStsToken.AccessKeyId && (getTime() + 300) < store.state.stsToken.Expiration) {
        return store.state.stsToken
    }else{
        await store.dispatch('getStsToken')
    }
    return store.state.stsToken
}
