export function isMobile() {
	return (window.matchMedia("(max-width:425px)").matches || navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
}
export function is_wechat() {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return userAgent.match(/MicroMessenger/i) == "micromessenger";
}
/**
 * 1: ios
 * 2: android
 * 3: 其它
 */
export const getOSType = () => {
	let u = navigator.userAgent;
	let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
	let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
	if (isIOS) {
		return 1;
	}
	if (isAndroid) {
		return 2;
	}
	return 3;
}
