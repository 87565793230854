// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/5/19
// +-------------------------------------------------------------
// |Time: 15:09
// +-------------------------------------------------------------
import realRequest from "@/utils/request";

const request = (obj) => {
    if (obj.data === true) return obj.url
    return realRequest(obj);
}
// 登录
export const login = (data) => realRequest({ url: '/login/login', method: 'post', data })
// 退出
export const logout = (data) => request({ url: '/login/logout', method: 'post',data })
