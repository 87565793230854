export default [
    {
        path: "/promote/info",
        name: "PromoteBaseInfo",
        meta: {
            title: "基本信息",
            menu:true,
        },
        component: () => import("@/views/promote/BaseInfo.vue")
    },
    {
        path: "/promote/settlement",
        name: "PromoteSettlement",
        meta: {
            title: "结算信息",
            menu:true,
        },
        component: () => import("@/views/promote/Settlement.vue")
    },
    {
        path: "/promote/change-password",
        name: "ChangePassword",
        meta: {
            title: "修改密码",
            menu:true,
        },
        component: () => import("@/views/promote/ChangePassword.vue")
    },
    {
        path: "/promote/change-second-pass",
        name: "ChangeSecondPass",
        meta: {
            title: "修改二级密码",
            menu:true,
        },
        component: () => import("@/views/promote/ChangeSecondPass.vue")
    }
]
