// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/5/25
// +-------------------------------------------------------------
// |Time: 17:02
// +-------------------------------------------------------------
import realRequest from '@/utils/request'

const request = (obj) => {
    if (obj.data === true) return obj.url
    return realRequest(obj);
}

// 统计
export const homeStatistic = (data) => request({ url: '/home/statistic', method: 'post', data: data })
// 渠道信息
export const promoteInfo = (data) => request({ url: '/home/promote-info', method: 'post', data: data })
// 推广链接
export const shareInfo = (data) => request({ url: '/home/share', method: 'post', data: data })
// 修改基本信息/结算信息
export const update = (data) => request({ url: '/home/update', method: 'post', data: data })
// 修改密码
export const changePass = (data) => request({ url: '/home/change-pass', method: 'post', data: data })
// 修改二级密码
export const changeSecondPass = (data) => request({ url: '/home/change-second-pass', method: 'post', data: data })
