// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/9/23
// +-------------------------------------------------------------
// |Time: 14:41
// +-------------------------------------------------------------
import request from "@/utils/request";

export const stsToken = () =>
    request({
        url: '/tool/get-ali-oss-sts',
        method: 'post',
        data: {},
    })
