// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/29
// +-------------------------------------------------------------
// |Time: 15:41
// +-------------------------------------------------------------
import {createStore} from "vuex";
import {logout} from "@/api/login";
import {promoteInfo} from "@/api/home";
import {getTime, utcToTimestamp} from "@/utils/time";
import {stsToken} from "@/api/tool";

export default createStore({
    state:{
        tabBarActive:localStorage.getItem('tabBarActive'),
        theme:'',
        userInfo: [],
        noticeStatus:true,
        // 阿里云上传
        stsToken: {
            SecurityToken: '',
            AccessKeyId: '',
            AccessKeySecret: '',
        },
    },
    mutations:{
        login(state, data) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('uid', data.id);
        },
        updateUserInfo(state,data){
            state.userInfo = data
        },
        changeTabBar(state,data){
            localStorage.setItem('tabBarActive', data);
            state.tabBarActive = data
        },
        updateNoticeStatus(state){
            state.noticeStatus = false
        },
        async logout(state, is_req = true) {
            if (is_req) await logout();
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            state.userInfo = [];
            state.tabBarActive = '';
            state.noticeStatus = true;
        },
    },
    getters:{
        async getStsToken(state) {
            // stsToken存在 且当前时间在有效期内
            if (state.stsToken.AccessKeyId && (getTime() + 300) < state.stsToken.Expiration) {
                return state.stsToken
            }
            await stsToken().then(res => {
                state.stsToken.SecurityToken = res.data.Credentials.SecurityToken;
                state.stsToken.AccessKeyId = res.data.Credentials.AccessKeyId;
                state.stsToken.AccessKeySecret = res.data.Credentials.AccessKeySecret;
                state.stsToken.Expiration = utcToTimestamp(res.data.Credentials.Expiration);
            })
            return state.stsToken
        },
    },
    actions:{
        getUserInfo({commit}){
            promoteInfo().then(({data})=>{
                commit('updateUserInfo',data)
            })
        }
    },
    modules:{}
})
