// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/5/19
// +-------------------------------------------------------------
// |Time: 17:09
// +-------------------------------------------------------------
import {createRouter, createWebHistory} from "vue-router";
import {getLocalStorage} from "@/utils/make";
import Item from "@/router/module/item";
import Promote from "@/router/module/promote";
import wallet from "@/router/module/wallet";

const routes = [
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: '/login',
        name: "CpsLogin",
        meta: {
            title: "登录"
        },
        component: () => import('@/views/Login')
    },
    {
        path: "/index",
        name: "cpsIndex",
        meta: {
            title: "首页",
            menu:true,
        },
        component: () => import("@/views/Index"),
        children:[
            {
                path: "/home",
                name: "CpsHome",
                meta: {
                    title: "首页",
                    menu: true,
                },
                component: () => import("@/views/Home")
            },
            {
                path: "/notice/detail/:ids/:type",
                name: "noticeDetail",
                meta: {
                    title: "公告通知详情",
                    menu: true,
                },
                component: () => import("@/views/article/NoticeDetail.vue")
            },
            {
                path: "/message/detail/:ids",
                name: "messageDetail",
                meta: {
                    title: "系统消息详情",
                    menu: true,
                },
                component: () => import("@/views/article/MessageDetail.vue")
            },
            {
                path: "/sham-search",
                name: "cpsShamSearch",
                meta: {
                    title: "钓鱼库",
                    menu:true,
                },
                component: () => import("@/views/data/ShamSearch")
            },
            ...Item,
            ...Promote,
            ...wallet,
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// 退出后路由重置
export function resetRouter() {
    const newRouter = createRouter({
        history: createWebHistory(),
        routes
    })
    router.matcher = newRouter.matcher // reset router
}


router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 青菜手游渠道后台`;
    // 判断是否登陆
    const localStorageData = getLocalStorage();
    if ((!localStorageData?.uid || !localStorageData?.token) && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
})
export default router
