// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/6/27
// +-------------------------------------------------------------
// |Time: 10:43
// +-------------------------------------------------------------
export default [
    {
        path: '/wallet/info',
        name: "WalletInfo",
        meta: {
            title: "钱包详情"
        },
        component: () => import("@/views/wallet/WalletInfo"),
    },
    {
        path: '/wallet/log',
        name: "WalletLog",
        meta: {
            title: "钱包明细"
        },
        component: () => import("@/views/wallet/walletLog/List"),
    },
    {
        path: '/wallet/withdraw-apply',
        name: "WithdrawApply",
        meta: {
            title: "立即提现"
        },
        component: () => import("@/views/wallet/withdraw/Apply"),
    },
    {
        path: '/wallet/withdraw-list',
        name: "WithdrawList",
        meta: {
            title: "提现明细"
        },
        component: () => import("@/views/wallet/withdraw/List")
    }
]
