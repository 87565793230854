import md5 from 'js-md5'
import { getTime } from "@/utils/time";
import { isNull } from 'lodash-es';

export const makeSign = function (object) {
  object.timestamp = getTime();

  const newObject = objectToArr(object)
  let signString = objectToString(newObject)
  signString = signString.slice(0, -1)

  const key = process.env.VUE_APP_SIGN_KEY;
  object.sign = md5(md5(signString + key) + key);
  return object
}

// 对象转数组，并排序
const objectToArr = function (object, addkey = '') {
  let arrs = {}
  for (let i in object) {
    if (typeof object[i] !== 'undefined') {
      let newkey = addkey + (addkey === '' ? i : '[' + i + ']')
      if (typeof object[i] !== 'object' || isNull(object[i])) {
        arrs[newkey] = object[i]
      } else {
        Object.assign(arrs, objectToArr(object[i], newkey));
      }
    }
  }
  let sortKey = Object.keys(arrs).sort()
  let newObj = {}// 创建一个新的对象，用于存放排好序的键值对

  // 此处不能使用for..in
  sortKey.forEach(function (val) {
    newObj[val] = arrs[val]// 向新创建的对象中按照排好的顺序依次增加键值对
  })
  return newObj
}

// 对象转字符串
function objectToString(object, str = '') {
  for (var i in object) {
    if (typeof object[i] !== 'object' || isNull(object[i])) {
      str += i + '=' + (isNull(object[i]) ? '' : object[i]) + '&'
    } else {
      str += objectToString(object[i])
    }
  }
  return str
}


